export function appStateReducer(draftState, action) {
  switch (action.type) {

    case 'sign-out': {
      delete draftState.token;
      delete draftState.refresh;
      delete draftState.claims;
      break;
    }
    case 'sign-in': {
      const {token, claims, refresh} = action.payload;
      draftState.token = token;
      draftState.refresh = refresh;
      draftState.claims = claims;
      if (refresh) {
        window.localStorage.setItem('refresh', refresh);
      }
      break;
    }
    default:
      return draftState;
  }
}
