import React from 'react';

import {AppContext} from "../../context/AppContext";
import {Redirect} from "react-router-dom";

export const Overview = () => {

  const {state: {token}} = React.useContext(AppContext);

  return (
    <>
      {!Boolean(token) && <Redirect to='/login'/>}
      {Boolean(token) && <Redirect to='/session'/>}
    </>
  )
};
