import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import React, {useContext, useState} from "react";
import Typography from "@material-ui/core/Typography";
import Icon from '@material-ui/core/Icon';
import {makeStyles} from "@material-ui/core";
import {AppDrawer} from "./AppDrawer";
import {AppContext} from "../context/AppContext";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export function EesnAppBar() {
  const classes = useStyles();
  const [drawer, setDrawer] = useState(false);
  const {state, dispatch} = useContext(AppContext);

  return <AppBar position="static">
    <Toolbar>
      <IconButton edge="start" className={classes.menuButton}
                  style={{visibility: state.token? 'visible': 'hidden'}}
                  onClick={() => setDrawer(true)}
                  color="inherit" aria-label="Menu">
        <Icon>menu</Icon>
      </IconButton>
      <AppDrawer open={drawer} onClose={() => setDrawer(false)}/>
      <Typography variant="h6" className={classes.title}>
        EESN
      </Typography>
      {Boolean(state.token) && (
        <IconButton edge="start" className={classes.menuButton}
                    onClick={() => dispatch({type: 'sign-out'})}
                    color="inherit" aria-label="Logout button">
          <Icon>exit_to_app</Icon>
        </IconButton>
      )}
    </Toolbar>
  </AppBar>;
}
