import {AppContext} from "../context/AppContext";
import {decode} from 'jsonwebtoken';
import {intersection} from 'lodash';
import * as React from "react";


export function withRoles(Component) {
  return (props) => <AppContext.Consumer>
    {({state: {token}}) => {
      const roles = [];
      if (token) {
        const decoded = decode(token);
        roles.push(...decoded.roles);
      }
      return <Component rolesFromToken={roles} {...props}/>
    }}
  </AppContext.Consumer>
}

function Role(props) {
  if (intersection(props.rolesFromToken, props.roles).length > 0) {
    return props.children;
  } else {
    return props.fallback? props.fallback: <></>;
  }
}

export const HasRole = withRoles(Role);
