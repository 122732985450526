import React from 'react';
import {Grid} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import Paper from "@material-ui/core/Paper";
import {LoginForm} from "./LoginForm";
import Box from "@material-ui/core/Box";

import imageSrc from './background.jpg';

const useStyles = makeStyles({
  root: {
    backgroundImage: `url(${imageSrc})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh'
  }
});

export default function LoginPage() {
  const classes = useStyles();

  return <Grid container className={classes.root} justify='center' alignItems='center'>
    <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
      <Box px={4} py={8}>
        <LoginForm/>
      </Box>
    </Grid>
  </Grid>;
}
