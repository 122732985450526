import React from "react";
import {withRouter} from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Icon from "@material-ui/core/Icon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import {HasRole} from "./HasRole";

const managerRoutes = [
  {text: 'Sitzungen', route: '/session', icon: 'assignment'}
  ];
const adminRoutes = [
  {text: 'Administration', route: '/admin', icon: 'settings'}
];

function MyDrawer(props) {
  return <Drawer open={props.open} onClose={() => props.onClose(false)}>
    <List>
      {managerRoutes.map(({text, route, icon}) => (
        <ListItem button key={text} aria-label={`Die Seite ${text} besuchen`} onClick={() => {
          props.history.push(route);
          props.onClose(false);
        }}>
          <ListItemIcon><Icon>{icon}</Icon></ListItemIcon>
          <ListItemText primary={text}/>
        </ListItem>
      ))}
    </List>
    <HasRole roles={['ADMIN']}>
      <Divider/>
      <List>
        {adminRoutes.map(({text, route, icon}) => (
          <ListItem button key={text} aria-label={`Die Seite ${text} besuchen`} onClick={() => {
            props.history.push(route);
            props.onClose(false);
          }}>
            <ListItemIcon><Icon>{icon}</Icon></ListItemIcon>
            <ListItemText primary={text}/>
          </ListItem>
        ))}
      </List>
    </HasRole>
  </Drawer>;
}

export const AppDrawer = withRouter(MyDrawer);
