import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import JavascriptTimeAgo from 'javascript-time-ago';
import de from 'javascript-time-ago/locale/de';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';


ReactDOM.render(<React.Suspense fallback={''}><App/></React.Suspense>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// Initialize the desired locales.
JavascriptTimeAgo.locale(de);
JavascriptTimeAgo.setDefaultLocale('de');
