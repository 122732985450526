import axios from "axios";

export function buildQueryFunction(path, getToken, direction = 'DESC') {
  return async query => {
    const requestUrl = `${path}?query=${query.search}&page=${query.page}&size=${query.pageSize}&sort=createdAt&direction=${direction}`;
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          'Authorization': `Bearer ${getToken()}`
        }
      });
      return {
        data: response.data.content,
        page: response.data.page,
        totalCount: response.data.total,
      }
    } catch (e) {
    }
  };
}

export function buildApiFunction(path, getToken) {
  return async query => {
    const requestUrl = `${path}?query=${query.search}&page=${query.page}&size=${query.pageSize}`;
    try {
      const response = await axios.get(requestUrl, {
        headers: {
          'Authorization': `Bearer ${getToken()}`
        }
      });
      return response.data;
    } catch (e) {
    }
  };
}
