import React from "react";

export const loadState = (key) => {
  try {
    const serialized = window.localStorage.getItem(key);
    if (!serialized) {
      return {};
    }
    return JSON.parse(serialized);
  } catch (e) {
    return {};
  }
};

export const saveState = (state, key) => {
  window.localStorage.setItem(key, JSON.stringify(state));
};

export function useSaveState(state, key) {
  React.useEffect(() => {
    saveState(state, key);
  }, [state, key])
}
