import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";

export default function AppLoading() {

  return <div style={{margin: 15}}>
    <Typography component='div' variant='body1' color='textPrimary' style={{textAlign: 'center'}}>
      <CircularProgress color="secondary"/>
    </Typography>

  </div>;
}
