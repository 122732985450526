import React, {useEffect, useState} from 'react';
import {AppContext} from "../../context/AppContext";
import {buildQueryFunction} from "../Admin/buildQueryFunction";
import {Typography} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Dialog from "@material-ui/core/Dialog";
import {Icon as MDIcon} from "@mdi/react";
import {mdiFileExcelBox, mdiFilePdfBox, mdiImage} from "@mdi/js";
import {Thumbnail} from "../../components/Thumbnail";
import {Preview} from "../../components/Preview";
import {useTheme} from "@material-ui/styles";
import {LocalizedMaterialTable} from "../../components/LocalizedMaterialTable";
import axios from "axios";
import Moment from "react-moment";

const DetailPanel = ({pictures}) => {
  const [show, setShow] = useState(false);
  const [url, setUrl] = useState(false);

  return <Box p={2}>
    <Grid container spacing={2}>
      {(!pictures || pictures.length === 0) && (
        <Box p={2}>
          <Typography variant='body1'>There are currently no images for this defect.</Typography>
        </Box>
      )}
      {(pictures && pictures.length > 0) && pictures.map(pic => (
        <Grid item key={pic.uuid}>
          <Thumbnail
            src={pic.url}
            onClick={() => {
              setUrl(pic.url);
              setShow(true);
            }}
          />
        </Grid>
      ))}
    </Grid>
    <Dialog fullScreen={true} open={show} onClose={() => setShow(false)}>
      {url && <Preview
        src={url}
        onClose={() => setShow(false)}
      />}
    </Dialog>
  </Box>;
};

export const Defects = withRouter(props => {
  const {state: {token}} = React.useContext(AppContext);
  const {palette} = useTheme();
  const {id} = props.match.params;
  const [session, setSession] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const result = await axios.get(`/eesn-service/api/session/${id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      );
      setSession(result.data);
    };
    fetch()
      .then(_ => console.log('fetched.'))
      .catch(_ => console.log('fetch failed.'));
  }, [id, token, setSession]);

  return <Box p={2}>
      {session && (
        <Box fontWeight="fontWeightBold">
          <Typography variant="h5"><Box fontWeight="500">Filiale {session?.store?.number}</Box></Typography>
          <Typography variant="subtitle1"><Moment date={session.started} format='DD.MM.YYYY HH:mm'/></Typography>
        </Box>
      )}
    <LocalizedMaterialTable
      style={{marginTop: 15}}
      title='Fehler'
      detailPanel={
        rowData => <DetailPanel pictures={rowData.pictures}/>
      }
      options={{debounceInterval: 500}}
      actions={[
        {
          icon: () => <MDIcon size={1} path={mdiFileExcelBox} color={palette.secondary.dark}/>,
          disabled: !Boolean(id),
          tooltip: 'Session als XLSX',
          isFreeAction: true,
          onClick: () => {
            window.open(`/eesn-service/api/export/xlsx/${id}`, '_blank');
          }
        },
        {
          icon: () => <MDIcon size={1} path={mdiFilePdfBox} color={palette.secondary.dark}/>,
          disabled: !Boolean(id),
          tooltip: 'Session als PDF',
          isFreeAction: true,
          onClick: () => {
            window.open(`/eesn-service/api/export/pdf/${id}`, '_blank');
          }
        },
        {
          icon: () => <MDIcon size={1} path={mdiImage} color={palette.secondary.dark}/>,
          disabled: !Boolean(id),
          tooltip: 'Bilder als PDF',
          isFreeAction: true,
          onClick: () => window.open(`/eesn-service/api/export/pictures/${id}`, '_blank')
        }
      ]}
      columns={[
        {
          title: 'Produkt',
          field: 'product'
        },
        {
          title: 'Fehleranzahl',
          field: 'count'
        },
        {
          title: 'Kommentar',
          field: 'comment'
        },
        {
          title: 'Positionen',
          field: 'position',
          render: rowData => <Typography>{rowData.position.name}</Typography>
        },
        {
          title: 'EANs',
          field: 'eans',
          render: rowData =>
            <Typography>
              {rowData.eans && rowData.eans.map(e => e.code).join(', ')}
            </Typography>
        },
        {
          title: 'Status',
          field: 'statuses',
          render: rowData => <>
            {!rowData.statuses && <Typography>
              no status
            </Typography>}
            {rowData.statuses && rowData.statuses.map(s => (
              <Typography key={s.uuid}>{s.name}</Typography>
            ))}
          </>
        }
      ]} data={buildQueryFunction(
      id ? `/eesn-service/api/defects/session/${id}` : '/eesn-service/api/defects',
      () => token,
      'ASC'
    )}/>
  </Box>
});
