import React, {useState} from "react";
import PropTypes from "prop-types";
import {Paper} from "@material-ui/core";
import {makeStyles} from "@material-ui/styles";
import Grow from "@material-ui/core/Grow";


const useStyle = makeStyles({
  paper: props => ({
    display: 'inline-flex',
    cursor: props.hasClick? 'pointer': 'default'
  }),
  image: props => ({
    maxWidth: props.width || 150,
    maxHeight: props.height || 150,
  })
});

export const Thumbnail = props => {
  const classes = useStyle({
    hasClick: Boolean(props.onClick),
    width: props.width,
    height: props.height
  });
  const [loaded, setLoaded] = useState(false);

  return <Grow in={loaded}>
    <Paper
      className={classes.paper}
      elevation={1}
    >
      <img
        onClick={props.onClick}
        onLoad={() => setLoaded(true)}
        src={props.src}
        alt={props.alt || ''}
        className={classes.image}
      />
    </Paper>
  </Grow>
};

Thumbnail.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  src: PropTypes.string,
  alt: PropTypes.string,
  onClick: PropTypes.func,
};
