import React from "react";
import {AppContext} from "../context/AppContext";
import {Redirect, Route} from "react-router-dom";
import {withRoles} from "./HasRole";
import {useSnackbar} from 'notistack';

export const SecuredRoute = withRoles(function (props) {
  const { enqueueSnackbar } = useSnackbar();
  const {state} = React.useContext(AppContext);
  const {userRole, rolesFromToken, ...restProps} = props;

  if (!state.token) {
    enqueueSnackbar('Sie wurden abgemeldet.');
    return <Redirect to='/login'/>
  }

  if (userRole && !rolesFromToken.includes(userRole)) {
    enqueueSnackbar('Sie besitzen nicht die erforderlichen Rechte um die Seite aufzurufen.', {variant: 'warning'});
    return <Redirect to='/'/>
  }

  return <Route {...restProps}/>
});
