import React, {useContext, useEffect, useState} from "react";
import {AppContext} from "./AppContext";
import {decode} from "jsonwebtoken";
import axios from "axios";

function isTokenValid(token) {
  if (!token) {
    return false;
  }

  let isValid = true;

  try {
    const decodedToken = decode(token);
    const now = Math.floor(Date.now() / 1000);
    if (now > decodedToken.exp) {
      isValid = false;
    }
  } catch (e) {
    console.error('could not decode token');
    isValid = false;
  }

  return isValid;
}

function createInstances(token) {
  return {
    eesn: axios.create({
      headers: {
        'Authorization': `Bearer ${token}`
      },
      baseURL: '/eesn-service'
    }),
    identity: axios.create({
      headers: {
        'Authorization': `Bearer ${token}`
      },
      baseURL: '/identity-service'
    })
  };
}

const ServiceContext = React.createContext(null);

export const useServiceContext = () => {
  return useContext(ServiceContext);
};

export const ServiceContextProvider = props => {
  const {state: {token, refresh}, dispatch} = useContext(AppContext);

  const [instances, setInstances] = useState(createInstances(token));

  useEffect(() => {
    if (!isTokenValid(token)) {
      if (isTokenValid(refresh)) {
        // try refresh
        axios.put('/identity-service/api/token', {refresh})
          .then(data => dispatch({
            type: 'sign-in',
            payload: data
          }))
          .catch(reason => dispatch({
            type: 'sign-out'
          }))
      } else {
        dispatch({
          type: 'sign-out'
        })
      }
    }
    setInstances(createInstances(token));
  }, [token, refresh, dispatch]);

  return <ServiceContext.Provider value={instances}>
    {props.children}
  </ServiceContext.Provider>
};
