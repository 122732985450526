import React from 'react';
import MaterialTable from "material-table";

export const LocalizedMaterialTable = props => {
  return <MaterialTable
    {...props}
    localization={{
      pagination: {
        labelDisplayedRows: '{from}-{to} von {count}',
        labelRowsSelect: 'Reihen',
        labelRowsPerPage: 'Reihen pro Seite:',
        firstAriaLabel: 'Erste Seite',
        firstTooltip: 'Erste Seite',
        previousAriaLabel: 'Vorherige Seite',
        previousTooltip: 'Vorherige Seite',
        nextAriaLabel: 'Naechste Seite',
        nextTooltip: 'Naechste Seite',
        lastAriaLabel: 'Letzte Seite',
        lastTooltip: 'Letzte Seite',
      },
      toolbar: {
        nRowsSelected: '{0} Reihe(n) ausgewaehlt',
        searchTooltip: 'Tabelle nach Begriff durchsuchen',
        searchPlaceholder: 'Suchen',
        addRemoveColumns: 'Spalten erstellen und entfernen'
      },
      header: {
        actions: 'Aktionen'
      },
      body: {
        emptyDataSourceMessage: 'Keine Daten gefunden',
        filterRow: {
          filterTooltip: 'Filter'
        },
        editRow: {
          cancelTooltip: 'Abbrechen',
          deleteText: 'Eintrag wirklich entfernen?',
          saveTooltip: 'Speichern'
        },
        addTooltip: 'Erstellen',
        deleteTooltip: 'Entfernen',
        editTooltip: 'Bearbeiten',
      }
    }}
  />
};

LocalizedMaterialTable.propTypes = MaterialTable.propTypes;
