import React from 'react';
import {loginSchema} from "./LoginForm.schema";
import {Formik} from "formik";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import {AppContext} from "../../context/AppContext";
import {Typography} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import Icon from "@mdi/react";
import {mdiLockOutline} from "@mdi/js";
import Box from "@material-ui/core/Box";
import {useTheme} from "@material-ui/styles";

function Form(props) {
  const {
    values: {name, password},
    errors,
    touched,
    handleChange,
    handleSubmit,
    isValid,
    setFieldTouched
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };
  return (
    <form onSubmit={(e) => {
      e.preventDefault();
      handleSubmit();
    }}>
      <TextField
        required
        style={{marginTop: 15, marginBottom: 15}}
        id="name"
        name="name"
        helperText={touched.name ? errors.name : ""}
        error={touched.name && Boolean(errors.name)}
        label="Nutzername"
        variant="outlined"
        value={name}
        onChange={change.bind(null, "name")}
        fullWidth
      />
      <TextField
        required
        style={{marginTop: 15, marginBottom: 15}}
        id="password"
        name="password"
        helperText={touched.password ? errors.password : ""}
        error={touched.password && Boolean(errors.password)}
        label="Passwort"
        variant="outlined"
        fullWidth
        type="password"
        value={password}
        onChange={change.bind(null, "password")}
      />
      <Button
        style={{marginTop: 15, marginBottom: 15}}
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        disabled={!isValid}
      >
        Anmelden
      </Button>
    </form>
  );
}

export const LoginForm = withRouter(function (props) {

    const theme = useTheme();
    const {dispatch} = React.useContext(AppContext);
    const [failedLogin, setFailedLogin] = React.useState(false);

    return <>
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
        <Box display='flex'
             m={1} p={1}
             borderRadius={'50%'}
             style={{backgroundColor: theme.palette.primary.main}}>
          <Icon size={1} color={theme.palette.secondary.main} path={mdiLockOutline}/>
        </Box>
        <Typography component='h1' variant='h5'>EESN Anmeldung</Typography>
      </div>
      <Formik
        onSubmit={(data, {setStatus}) => {
          setFailedLogin(false);
          axios.post('/identity-service/api/token', {
            username: data.name,
            password: data.password
          }).then(value => {
            dispatch({
              type: 'sign-in',
              payload: value.data
            });
            props.history.replace('/');
          }).catch(reason => {
            setFailedLogin(true);
          });
        }}
        render={props => <Form {...props}/>}
        initialValues={{name: "", password: ""}}
        validationSchema={loginSchema}
      />
      <Typography hidden={!failedLogin} color='secondary' variant='body2'>Nutzername oder Password falsch.</Typography>
    </>
  }
)
