import React, {Suspense} from 'react';
import css from './App.module.css';
import {Overview} from "./containers/Overview";
import {AppContext} from "./context/AppContext";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {useImmerReducer} from "use-immer";
import {loadState, useSaveState} from "./localStorage";
import {appStateReducer} from "./AppStateReducer";
import {EesnAppBar} from "./components/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import {ThemeProvider} from '@material-ui/styles';
import {theme} from "./AppTheme";
import AppLoading from "./components/AppLoading";
import {Defects} from "./containers/Defects";
import {SnackbarProvider} from 'notistack';
import {SecuredRoute} from "./components/SecuredRoute";
import {ServiceContextProvider} from "./context/ServiceContext";
import LoginPage from "./containers/Login/LoginPage";

const Admin = React.lazy(() => import('./containers/Admin'));
const Sessions = React.lazy(() => import('./containers/Sessions/Sessions'));

function App() {
  const [state, dispatch] = useImmerReducer(appStateReducer, loadState('@state'));
  useSaveState(state, '@state');

  return (
    <SnackbarProvider maxSnack={3}>
      <ThemeProvider theme={theme}>
        <Router>
          <AppContext.Provider value={{state, dispatch}}>
            <ServiceContextProvider>
              <div className={css.App}>
                <CssBaseline/>
                {/*<Header/>*/}
                <Switch>
                  <Route path='/login' exact/>
                  <Route path='/' component={EesnAppBar}/>
                </Switch>
                <Suspense fallback={<AppLoading/>}>
                  <Switch>
                    <Route path='/' exact component={Overview}/>
                    <Route path='/login' exact component={LoginPage}/>
                    <SecuredRoute path='/admin' userRole='ADMIN' component={Admin}/>
                    <SecuredRoute path='/defects' exact component={Defects}/>
                    <SecuredRoute path='/session' exact component={Sessions}/>
                    <SecuredRoute path='/session/:id' component={Defects}/>
                  </Switch>
                </Suspense>
              </div>
            </ServiceContextProvider>
          </AppContext.Provider>
        </Router>
      </ThemeProvider>
    </SnackbarProvider>
  );
}

export default App;
