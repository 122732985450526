import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ffe600'
    },
    secondary: {
      main: '#dc0d14'
    },
  }
});
