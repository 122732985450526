import React from "react";
import PropTypes from "prop-types";
import {Paper} from "@material-ui/core";
import makeStyles from "@material-ui/styles/makeStyles";
import IconButton from "@material-ui/core/IconButton";
import {Icon} from '@mdi/react';
import {mdiClose} from '@mdi/js';
import Toolbar from "@material-ui/core/Toolbar";
import AppBar from "@material-ui/core/AppBar";

const useStyles = makeStyles({
  paper: {
    maxWidth: '100vw',
    height: '100vh',
    textAlign: 'center',
    overflow: 'auto',
  },
  imageBox: {
    maxWidth: '100%',
    marginTop: 16
  },
  appBar: {
    position: 'sticky',
    top: 0
  }
});

export const Preview = props => {
  const classes = useStyles();

  return <Paper
    className={classes.paper}
  >
    {(props.onClose) && (
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton onClick={props.onClose}>
            <Icon path={mdiClose} size={1}/>
          </IconButton>
        </Toolbar>
      </AppBar>
    )}
    <img
      onClick={props.onImageClick}
      className={classes.imageBox}
      src={props.src}
      alt={props.alt || ''}
    />
  </Paper>
};

Preview.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,

  onClose: PropTypes.func.isRequired,
  onImageClick: PropTypes.func
};
